<template>
    <div class="pvhFactory">
        <basic-container>
            <!--data 数据源 v-model：对象行 -->
            <avue-crud :data="data1" ref="crud" v-model="obj" :option="setData.option" :page.sync="page"
                       @current-change="currentChange"
                       @refresh-change="refreshChange">
                <template slot="menuLeft" style="width: calc(100% - 80px);">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                            <el-input v-model.trim="productName" placeholder="请输入名称" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;"></el-input>
                            <el-input v-model.trim="productCode" placeholder="请输入编码" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;"></el-input>
                            <el-input v-model.trim="standardCode" placeholder="请输入标准名称" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;"></el-input>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4"
                                style=" text-align: right; align-self: flex-end;">
                            <el-button type="primary" icon="el-icon-plus" size="mini" :title="$t('New')" plain @click="addInfo"> {{$t('New')}}</el-button>
                            <el-button :title="$t('Refresh')" type="primary" icon="el-icon-search" size="mini" plain @click="refreshChange"> {{$t('Refresh')}}</el-button>
                            <el-button :title="$t('Export')" type="primary" icon="el-icon-download" size="mini" plain @click="expo"> {{$t('Export')}}</el-button>
                        </el-col>
                    </el-row>
                </template>
                <template slot-scope="{row,index}" slot="menu">
                    <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')"
                               @click="updateInfo(row,index)"></el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {list,exportWuliao} from "@/api/materialComparison";
    import {getGrop} from "@/util/auth";

    export default {
        data() {
            return {
                dialogVisible: true,
                productName: '',
                productCode: '',
                standardCode:'',
                obj: {},
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: false,
                    pageSize: 100
                },
                data1: [],
                users: [],
            }
        },
        computed: {
            setData() {
                return {
                    option: {
                        page: true,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        // menu:false,
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        editBtn: false,
                        delBtn: false,
                        labelPosition: 'top',
                        menuAlign: 'center',
                        addBtn: false,
                        refreshBtn: false,
                        column: [
                            {
                                label: '标准名称',
                                prop: 'standardCode'
                            },
                            {
                                label: '产品物料编码',
                                prop: 'productCode'
                            },
                            {
                                label: '产品物料名称',
                                prop: 'productName'
                            },
                            {
                                label: '批次号',
                                prop: 'batchNo'
                            },
                            {
                                label: '样本大小',
                                prop: 'sampleSize'
                            },
                            {
                                label: '检验人',
                                prop: 'inspector'
                            },
                            {
                                label: '检验时间',
                                prop: 'createTime'
                            },
                        ]
                    }
                }
            }
        },
        created() {
            this.getList();
        },
        methods: {
            handleClose() {
                this.dialogVisible = false;
            },
            refreshChange() {
                // debugger
                this.getList()
            },
            unique(arr, val) {
                const res = new Map();
                return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))
            },
            addInfo() {
              this.$router.push({ path: '/quality/materialComparisonAdd', query: { sign: 'add', ID: '' } })
            },
            //修改
            updateInfo(row) {
                this.$router.push({
                    path: '/quality/materialComparisonAdd',
                    query: {sign: 'update', productCode: row.productCode}
                })
            },
            getList() {
                const xx = {
                    pageNo: this.page.currentPage,
                    pageSize: this.page.pageSize,
                    total: this.page.total,
                    productCode: this.productCode,
                    productName: this.productName,
                    standardCode:this.standardCode,
                };
                list(xx).then(res => {
                    console.log('res', res);
                    this.newData = this.unique(res.data.data.items, "productCode");
                    console.log("根据productCode去重",this.newData);
                    debugger
                    if (res.data.data == null) {
                        this.data1 = [];
                        this.page.total = 0;
                    } else {
                        console.log('res.data.data.items', res.data.data.items);
                        this.page.total = this.newData.length;
                        this.data1 = this.newData.map(item => {
                            return {
                                ...item,
                                complianceWithStandards: item.complianceWithStandards === 1 ? '合格' : '不合格'
                            }
                        })
                    }
                    console.log(res, 'res')
                }).catch(erro => {
                    console.log(erro);
                })
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getList()
            },
            expo(){

                window.location.href = `${this.proApis.MATERIALCOMPARISONEXPORT}?corpId=${getGrop()}`;
                console.log(`${this.proApis.MATERIALCOMPARISONEXPORT}?corpId=${getGrop()}`)
                // exportWuliao(corpId).then(res => {
                //     console.log(res, 'res')
                // }).catch(erro => {
                //     console.log(erro);
                // })
            },
            //watch:用于监听data里面的数据是否被修改，一旦修改就可以执行一些其他的操作
            watch: {
                //监听路由对象是否变化
                $route() {
                    this.getList()
                }
            },
        }
    }
</script>
<style scoped>
</style>
